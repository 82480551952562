import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';

const _FooterWrapper = styled.footer<RouteComponentProps>`
  position: fixed;
  display: block;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px;
  bottom: 0px;
  left: 20px;
  
  text-align: right;
  ${props => (
    props.match.path.startsWith('/record') ||
    props.match.path.startsWith('/message') ||
    props.match.path.startsWith('/upload') ||
    props.match.path.startsWith('/preview')||
    props.match.path.startsWith('/end')
  ) && css`
    background-color: transparent;
  `
  }
  @media (max-width: 991px){
    position: relative;
    left: inherit;
    bottom: inherit;
    margin-top: 50px;
    text-align: center;
    display: inline-block;
    justify-content: inherit;
    width: 100%;
  }
`;

const _ListFooterLink = styled.ul<RouteComponentProps>`
  margin: 0px;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 4px;
  padding-top: 15px;
  @media (max-width: 991px){
    width: 80%;
    background-color: transparent;
    margin: 0 auto;
    text-align: center;
    padding: 0px;
    padding-bottom: 30px;
  }
  &.right{
    text-align: left;
    float: left;
    @media (max-width: 991px){
        float: inherit;
        text-align: center;
      }
    li{
      text-align: left;
      float: left;
      &:first-child{
        margin-bottom: 5px;
      }
      @media (max-width: 991px){
        float: inherit;
        text-align: center;
        display: inline-block;
      }
    }
  }
  span{
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
  }
  li {
    list-style: none;
    float: right;
    padding: 0px 4px;
    font-size: 10px;
    color: #303E48;
    text-transform: uppercase;
    @media (max-width: 991px){
        float: inherit;
        padding: 0px 10px;
        text-align: center;
        color: #303E48;
        display: inline-block;
      }
    &:last-child{
      border-right: 0px;
    }
    a{
      color: #303E48;
      text-decoration: inherit;
      margin: 0px;
      font-weight: normal;
      @media (max-width: 991px){
        color: #303E48;
      }
      &:hover{
        color: #303E48;
      }
    }
  }
  ${props => (
    props.match.path.startsWith('/message')
  ) && css`
    @media (max-width: 991px){
      li{
        a{
          @media (max-width: 991px){
            color: #303E48;
          }
        }
      }
    }
    
  `
  }
`;

export const ListFooterLink = withRouter(_ListFooterLink);
export const FooterWrapper = withRouter(_FooterWrapper);