import { colors } from './../../variables';
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  height: 50px;
  position: relative;
  padding-left: 40px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  @media (max-width: 991px){
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    padding-top: 15px;
  }
`;

const _Logo = styled.img<RouteComponentProps>`
  width: 92px;
  height: auto;
  position: fixed;
  left: 40px;
  padding-right: 20px;
  top: 40px;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  margin-right: 20px;
  border-right: 1px solid #303E48;
  @media (max-width: 991px){
    width: 70px;
    position: relative;
    top: inherit;
    margin-right: 10px;
    padding-right: 15px;
    z-index: 2;
    left: inherit;
    text-align: center;
    top: 0px;
  }
  display: none;
  ${props => (
    props.match.path.startsWith('/end') 
  ) && css`
    @media (max-width: 991px){
      display: block;
    }
  `
  }
  ${props => (
    props.match.path.startsWith('/record') ||
    props.match.path.startsWith('/message') ||
    props.match.path.startsWith('/upload') ||
    props.match.path.startsWith('/preview')
  ) && css`
    display: block;
  `
  }
`;

const _Catchline = styled.h1<RouteComponentProps>`
  position: relative;
  font-size: 30px;
  font-family: 'AndradePro', 'Helvetica';
  color: #303E48;
  position: fixed;
  left: 170px;
  top: 45px;
  display: none;
  br{
    display: none;
  }
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    margin-top: 0px;
    position: relative;
    font-size: 14px;
    top: 0px;
    line-height: 16px;
    br{
      display: block;
    }
  }

  ${props => (
    props.match.path.startsWith('/upload') ||
    props.match.path.startsWith('/preview') 
  ) && css`
    display: block;
    @media (max-width: 991px){
      display: block;
    }
    
  `
  }

${props => (
    props.match.path.startsWith('/message') ||
    props.match.path.startsWith('/record')
  ) && css`
    display: block; 
    
  `
  }
${props => (
    props.match.path.startsWith('/end') 
  ) && css`
    @media (max-width: 991px){
      display: block;
    }
  `
  }

${props => (
    props.match.path.startsWith('/message') 
  ) && css`
    @media (max-width: 991px){
      color: #000!important;
      z-index: 10;
    }
    
  `
  }
`;

export const Catchline = withRouter(_Catchline);
export const Logo = withRouter(_Logo);