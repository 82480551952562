import styled from "styled-components";
import { colors } from './../../variables';



export const Steps = styled.div`
  position: fixed;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px){
    top: 100px;
    z-index: 20;
  }
`;
export const StepsElt = styled.div`
  text-align: center;
  margin: 0px 10px;
  position: relative;
  &:after{
    width: 150%;
    height: 1px;
    background-color: #303E48;
    content: '';
    position: absolute;
    top: 20px;
    z-index: 1;
    @media (max-width: 991px){
      width: 130%;
      top: 15px;
    }
  }
  &:last-child{
    &:after{
      display: none;
    }
  }
`;
export const StepsNumber = styled.div`
  width: 40px;
  z-index: 2;
  position: relative;
  height: 40px;
  margin: 0 auto;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #303E48;
  text-align: center;
  line-height: 46px;
  font-family: 'AndradePro', 'Helvetica';
  color: #303E48;
  font-size: 30px;
  @media (max-width: 991px){
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  &.active{
    color: #fff;
    background-color: #303E48;
  }
`;
export const StepsName = styled.div`
  font-size: 16px;
  margin-top: 10px;
  @media (max-width: 991px){
    font-size: 12px;
  }
`;

export const UploadWrapper = styled.div`
  width: 900px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin: 0 auto;
  margin-top: 80px;
  padding: 50px 0px;
  z-index: 30;
  @media (max-width: 991px){
    position: relative;
    border: 0px;
    margin: 0 auto;
    margin-top: 20px;
    width: auto;
    left: inherit;
  }
  @media (max-width: 767px){
    position: relative;
  }
`;

export const UploadContent = styled.div`
  padding: 40px 20px;
  color: #303E48;
  text-align: center;
  @media (max-width: 991px){
    color: #303E48;
  }
  @media (max-width: 767px){
    padding: 20px;
    padding-bottom: 50px;
  }
`;

export const UploadGif = styled.div`
  text-align: center;
  img{
    width: 42px;
    height: auto;
  }
`;

export const UploadLoader = styled.div`
  font-size: 70px;
  color: #303E48;
  @media (max-width: 991px){
    color: #303E48;
  }
  sup{
    font-size: 20px;
  }
`;

export const UploadLoaderText = styled.div`
  font-size: 20px;
  color: #303E48;
  font-weight: 300;
  @media (max-width: 991px){
    color: #303E48;
  }
  sup{
    font-size: 20px;
  }
`;

export const UploadTitle = styled.div`
  margin-top: 20px;
  font-size: 60px;
  line-height: 50px;
  color: #303E48;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'AndradePro', 'Helvetica';
  @media (max-width: 991px){
    color: #303E48;
    font-size: 38px;
  }
`;

export const UploadMessage = styled.div`
  text-transform: normal;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 300;
  color: #303E48;
  @media (max-width: 991px){
    color: #303E48;
    font-size: 16px;
  }
`;