import * as React from 'react';
import { LanguagesWrapper, LanguagesSelectElement, LanguagesSelect, LanguagesOption } from './styled';

import livstickApp from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  return <LanguagesWrapper>
    <LanguagesSelectElement>
      <LanguagesSelect onChange={e => {
        livstickApp.changeLang(e.currentTarget.value);
      }}>
        
        <LanguagesOption selected value="en">En</LanguagesOption>
      </LanguagesSelect>
    </LanguagesSelectElement>
  </LanguagesWrapper>;
}

export default Languages;
