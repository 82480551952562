import styled, { css } from "styled-components";
import { colors } from './../../variables';


export const Steps = styled.div`
  position: fixed;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px){
    top: 10px;
    z-index: 20;
    position: absolute;
  }
`;
export const StepsElt = styled.div`
  text-align: center;
  margin: 0px 10px;
  position: relative;
  &:after{
    width: 150%;
    height: 1px;
    background-color: #303E48;
    content: '';
    position: absolute;
    top: 20px;
    z-index: 1;
    @media (max-width: 991px){
      width: 130%;
      top: 15px;
    }
  }
  &:last-child{
    &:after{
      display: none;
    }
  }
`;
export const StepsNumber = styled.div`
  width: 40px;
  z-index: 2;
  position: relative;
  height: 40px;
  margin: 0 auto;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #303E48;
  text-align: center;
  line-height: 46px;
  font-family: 'AndradePro', 'Helvetica';
  color: #303E48;
  font-size: 30px;
  @media (max-width: 991px){
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  &.active{
    color: #fff;
    background-color: #303E48;
  }
`;
export const StepsName = styled.div`
  font-size: 16px;
  margin-top: 10px;
  @media (max-width: 991px){
    font-size: 12px;
  }
`;

export const Catchline = styled.h1`
  position: relative;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 26px;
    line-height: 28px;
    color: #fff;
    position: relative;
    display: block;
  }
`;

export const ConfirmWrapper = styled.div`
  width: 900px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  padding: 30px 0px;
  z-index: 30;
  @media (max-width: 991px){
    position: relative;
    border: 0px;
    margin: 0 auto;
    margin-top: 20px;
    width: auto;
    left: inherit;
    width: 100%;
  }
  @media (max-width: 767px){
    position: relative;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #303E48;
`;

export const ConfirmContent = styled.div`
  padding: 20px 40px;
  color: #fff;
  text-align: center;
  button{
    display: inline-block;
    @media (max-width: 991px){
      padding: 0px 50px;
    }
  }
`;

export const ConfirmTitle = styled.div`
  margin-top: 20px;
  font-size: 60px;
  line-height: 50px;
  color: #303E48;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'AndradePro', 'Helvetica';
  @media (max-width: 991px){
    color: #303E48;
    font-size: 38px;
  }
`;

export const ConfirmMessage = styled.div`
  text-transform: normal;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 300;
  color: #303E48;
  @media (max-width: 991px){
    color: #303E48;
    font-size: 16px;
  }
`;

export const ResumeVideo = styled.div`
  background-color: #000;
  width: 400px;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid #aaa;
  @media (max-width: 767px){
    width: 100%;
    height: 200px;
  }
`;

export const ShareTitle = styled.div`
  font-size: 16px;
  margin-top: 20px;
  color: #303E48;
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 5px;
  color: #303E48;
  
  a{
    display: flex;
    font-size: 11px;
    padding: 5px 2px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
  }
  img{
    height: 15px;
    width: auto;
    margin-right: 5px;
  }
`;

export const Logo = styled.img`
  width: 120px;
  height: auto;
  margin: 0 auto;
  position: relative;
  @media (max-width: 991px){
    
  }
`;