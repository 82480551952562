import * as React from "react";
import { hot } from "react-hot-loader";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import RecordPage from "./pages/record";
import RecordPageDefault from "./pages/record/index_default";
import PreviewPage from "./pages/preview";
import MessagePage from "./pages/message";
import UploadPage from "./pages/upload";
import ErrorPage from "./pages/error";
import EndPage from "./pages/end";
import LandingPage from "./pages/landing";
import GatePage from "./pages/gate";
import SortPage from "./pages/sort";
import manifest from "./manifest";
import { IntlProvider } from "react-intl";
import translations, { translationsEn, translationsEs, translationsIt, translationsDe, translationsZh, translationsJa } from "./lang/fixture";
import livstickApp from "./models/livstick";

const lang = {
  'fr': translations,
  'en': translationsEn,
  'es': translationsEs,
  'it': translationsIt,
  'de': translationsDe,
  'zh': translationsZh,
  'ja': translationsJa
}

import {
  isSafari
} from "react-device-detect";


const App:React.FC = () => {
  const [language, setLanguage] = React.useState<string>('en');

  React.useEffect(() => {
    livstickApp.onChangeLang = [(lang) => setLanguage(lang)];
  },[])

  return (
    <IntlProvider locale={'fr'} messages={lang[language]}>
    <Router>
      {manifest.VM ? 
      <Switch>
        <Route component={LandingPage} exact path="/" />
        <Route component={RecordPage} exact path="/record" />
        <Route component={PreviewPage} exact path="/preview" />
        <Route component={MessagePage} exact path="/message" />
        <Route component={UploadPage} exact path="/upload" />
        <Route component={EndPage} exact path="/end" />
        <Route component={LandingPage} exact path="/landing" />
        <Route component={SortPage} exact path="/sort" />
        <Route component={PreviewPage} exact path="/view/:id" />
        <Route component={PreviewPage} exact path="/preview/:id" />
        <Route component={RecordPage} exact path="/edit/:id" />
        <Route component={PreviewPage} exact path="/answer/:id" />
        <Route component={PreviewPage} exact path="/:id" />
        <Route component={ErrorPage} />
      </Switch>
      :
      <Switch>
        <Route component={LandingPage} exact path="/" />
        <Route component={isSafari ? RecordPage : RecordPageDefault} exact path="/record" />
        <Route component={RecordPage} exact path="/new" />
        <Route component={PreviewPage} exact path="/preview" />
        <Route component={MessagePage} exact path="/message" />
        <Route component={UploadPage} exact path="/upload" />
        <Route component={EndPage} exact path="/end" />
        <Route component={LandingPage} exact path="/landing" />
        <Route component={SortPage} exact path="/sort" />
        <Route component={PreviewPage} exact path="/view/:id" />
        <Route component={PreviewPage} exact path="/preview/:id" />
        <Route component={RecordPage} exact path="/edit/:id" />
        <Route component={PreviewPage} exact path="/answer/:id" />
        <Route component={PreviewPage} exact path="/:id" />
        <Route component={ErrorPage} />
      </Switch>}
    </Router>
    </IntlProvider>
  );
}

declare let module: object;

export default hot(module)(App);
