import * as React from 'react';
import Layout from '../layout';
import {  Steps, StepsElt, StepsNumber, StepsName, Logo, Catchline, ConfirmWrapper, ConfirmMessage, ConfirmTitle, ConfirmContent, Sep, ResumeVideo, ShareTitle, ShareList, ShareListItem } from './styled';
import { colors } from '../../variables';
import { Button, MobileMask } from '../../widgets/globaleStyledWidget';
import icoFacebook from './images/facebook.png';
import icoInsta from './images/insta.png';
import icoTwitter from './images/twitter.png';
import icoPinterest from './images/pinterest.png';
import logo from './images/logo.svg';

import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import livstickApp from '../../models/livstick';

import videojs from 'video.js';
import manifest from '../../manifest';
import { OnDesktop } from '../record/styled';

const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};


const EndPage:React.FunctionComponent = () => {

  const videoEl = React.useRef(null);

  const intl = useIntl();

  const start = async () => {
    const _res = await livstickApp.pullNoLog();
    

    console.log('ref', videoEl)
    var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION;
      videojs.log(version_info);
      _player.src({ src: _res.media_url });
      _player.load();
      //_player.play();
    });

  }

  React.useEffect(() => {
    start();
  }, [])
    return <Layout>
      <ConfirmWrapper>
      <Steps>
        <StepsElt>
          <StepsNumber className="active">1</StepsNumber>
          <StepsName className="active"><FormattedMessage id="global_txt_record"/></StepsName>
        </StepsElt>
        <StepsElt>
          <StepsNumber className="active">2</StepsNumber>
          <StepsName className="active"><FormattedMessage id="global_txt_delivery"/></StepsName>
        </StepsElt>
        <StepsElt>
          <StepsNumber className="active">3</StepsNumber>
          <StepsName className="active"><FormattedMessage id="global_txt_save"/></StepsName>
        </StepsElt>
      </Steps>
        <ConfirmContent>
        <OnDesktop><Logo src={logo} /></OnDesktop>
        <ConfirmTitle><FormattedMessage id="form_txt_success_title"/></ConfirmTitle>
          {/* {livstickApp.planification && <>Votre message sera envoyé le {moment()}</>} */}
          {/* <FormattedMessage id="form_txt_sent" defaultMessage="Votre message sera envoyé le ..."/> */}
          <ConfirmMessage><FormattedMessage id="form_txt_success"/></ConfirmMessage>
          <ResumeVideo>
            <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
          </ResumeVideo>
          {manifest.VM && <p>https://mm2.fr/liv4/vm.html#/{livstickApp.code}</p>}
          
          {/*<Button color={colors.main} hover="main"><FormattedMessage id="form_txt_button_edit"/></Button>*/}
          {/* ROMAIN : Ajout pour HO */}
          <ShareTitle><FormattedMessage id="share_txt_share" defaultMessage="En attendant, soutenez-nous sur les réseaux !"/></ShareTitle>
          <ShareList>
            <ShareListItem color={colors.facebook}><a href={intl.formatMessage({id:'share_txt_facebook'})} target="_blank"><img src={icoFacebook} /></a></ShareListItem>
            <ShareListItem color={colors.instagram}><a href={intl.formatMessage({id:'share_txt_instagram'})} target="_blank"><img src={icoInsta} /></a></ShareListItem>
            <ShareListItem color={colors.twitter}><a href={intl.formatMessage({id:'share_txt_twitter'})} target="_blank"><img src={icoTwitter} /></a></ShareListItem>
          </ShareList>
          
        </ConfirmContent>
      </ConfirmWrapper>

    </Layout>;
}

export default injectIntl(EndPage);