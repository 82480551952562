export const colors = {
  main: '#8960E8',
  secondary: '#fff',
  other: '#aaa',
  back: 'grey',
  red: '#D40132',
  black: '#000000',
  facebook: '#303E48',
  twitter: '#303E48',
  instagram: '#303E48'
}

export const fonts = {
  font: 'Montserrat',
  bold: '700',
  semibold: '600',
  regular: '400',
  light: '300'
}

export const align = {
  center: 'center',
  left: 'left',
  right: 'right'
}

export const lang = {
  title_vm: 'Le titre 2'
}